// firebase/index
export const firebaseConfig = {
  apiKey: "AIzaSyAZAUuEzLh-0ECsLfxitJNQL96r93TLj5I",
  authDomain: "pro-tech-latam.firebaseapp.com",
  databaseURL: "https://pro-tech-latam.firebaseio.com",
  projectId: "pro-tech-latam",
  storageBucket: "pro-tech-latam.appspot.com",
  messagingSenderId: "688151223261",
  appId: "1:688151223261:web:ffaceb5cb726d8f43c3434",
  measurementId: "G-NDQ8L320SV"
}

// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/pro-tech-latam.appspot.com/o/'